import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { YellowButton } from '../../button/button';
import { FullPageError } from '../full-page-error/full-page-error';

const Title = styled(Typography)`
  line-height: 1.4;
  margin-bottom: 20px;
`;

const Content = styled(Typography)`
  font-size: 18px;
  line-height: 1.4;
  margin-bottom: 20px;
`;

export const NotFoundError = () => {
  return (
    <FullPageError>
      <Title variant="h1">This page is missing.</Title>
      <Content>
        The link you clicked may be broken or the page may be gone but we are still here to kick it
        with ya!
      </Content>
      <YellowButton href="/" aria-label="Go home" label="Go home" />
    </FullPageError>
  );
};
